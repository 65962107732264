import classNames from "classnames";
import { Entry } from "contentful";
import { useInView } from "framer-motion";
import Image from "next/image";
import { useEffect, useMemo, useRef, useState } from "react";

import Container from "~/components/common/container";
import { useAnimationFrame } from "~/components/common/custom-hooks";
import EscapeNewLine from "~/components/common/escape-new-line";
import Grid from "~/components/common/grid";
import Icon from "~/components/common/icon";
import MediaAsset from "~/components/common/media-asset";
import { useBreakpoint } from "~/contexts/breakpoint";
import Editorials from "~/types/editorials";
import Constants from "~/utils/constants";
import contentfulUtils from "~/utils/contentful-utils";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";

import Mosaic from "../../common/mosaic/mosaic";
import styles from "./timed-editorial-carousel.module.scss";
import TimedEditorialCarouselSlide from "./timed-editorial-carousel-slide";

type Props = {
  entry: Entry<unknown>;
};

export default function TimedEditorialCarousel(props: Props) {
  assertEditorialType<Editorials.TimedEditorialCarousel>(props.entry, "timedEditorialCarousel");

  props.entry.fields.elements = props.entry.fields.elements.filter(isActiveEntry);

  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, {
    margin: `-${Constants.HEADER_HEIGHT_DESKTOP}px 0px -${Constants.HEADER_HEIGHT_DESKTOP}px 0px`,
  });
  const breakpoint = useBreakpoint();
  const inspectorMode = contentfulUtils.useInspectorMode(props.entry);

  const [activeIndex, setActiveIndex] = useState(0);
  const [autoplayStatus, setAutoplayStatus] = useState(false);
  const [manuallyPaused, setManuallyPaused] = useState(false);
  const [slidesMinHeight, setSlidesMinHeight] = useState(0);

  const duration = props.entry.fields.duration;
  const totalThumbs = props.entry.fields.elements.length;
  const countdown = useRef(duration);

  const activeElement = useMemo(() => {
    // i have to cast the types otherwise an error shows up
    return (props.entry as Entry<Editorials.TimedEditorialCarousel>).fields.elements[activeIndex];
  }, [activeIndex, props.entry]);

  useEffect(() => {
    if (!manuallyPaused && autoplayStatus != isInView) {
      setAutoplayStatus(isInView);
    }
  }, [autoplayStatus, isInView, manuallyPaused]);

  useEffect(() => {
    setAutoplayStatus(!manuallyPaused);
  }, [manuallyPaused]);

  // resets minSlidesHeight on breakpoint change
  useEffect(() => {
    if (ref.current) {
      setSlidesMinHeight(0);
    }
  }, [breakpoint]);

  // resets the countdown after activeIndex change
  useEffect(() => {
    countdown.current = duration;
    if (ref.current) {
      setSlidesMinHeight(ref.current.offsetHeight);
    }
  }, [activeIndex, duration]);

  useAnimationFrame((deltaTime) => {
    if (autoplayStatus) {
      if (countdown.current <= 0.1) {
        let nextIndex = activeIndex + 1 < totalThumbs ? activeIndex + 1 : 0;
        if (nextIndex != activeIndex) {
          countdown.current = duration;
          setActiveIndex(nextIndex);
        }
      } else {
        countdown.current = countdown.current - deltaTime * 0.001;
      }
    }
  });

  if (props.entry.fields.elements.length === 0) return null;

  return (
    <Container ref={ref} className={styles.wrapper} style={{ minHeight: slidesMinHeight }}>
      <Grid className={styles.grid}>
        <div className={styles.col1}>
          {props.entry.fields.introText ? (
            <p {...inspectorMode?.getProps("introText")} className={styles.introText}>
              <EscapeNewLine text={props.entry.fields.introText} />
            </p>
          ) : null}
          <div className={styles.thumbs} style={{ "--thumb-duration": `${duration}s` } as React.CSSProperties}>
            {props.entry.fields.elements.map((el, i) => (
              <button
                key={i}
                className={classNames(styles.thumb, { [styles.active]: i == activeIndex })}
                onClick={() => (i == activeIndex ? setManuallyPaused(!manuallyPaused) : setActiveIndex(i))}
              >
                {i == activeIndex && (
                  <Icon
                    className={styles.playState}
                    name={autoplayStatus ? "pause" : "play"}
                    fill={!autoplayStatus ? "currentColor" : undefined}
                  />
                )}
                <MediaAsset height={40} width={40} entry={el.fields.thumbnailImage} />
                <svg height={48} width={48} viewBox="0 0 48 48">
                  <circle opacity=".2" cx="24" cy="24" r="23" stroke="url(#a)" strokeWidth="2" fill="none" />
                  <circle
                    id={`thumb-${i}`}
                    cx="24"
                    cy="24"
                    r="23"
                    stroke="url(#b)"
                    strokeWidth="2"
                    fill="none"
                    pathLength="1"
                    strokeDashoffset="0px"
                    strokeDasharray="0px 1px"
                    className={classNames(
                      styles.circle,
                      { [styles.animateCircle]: activeIndex == i },
                      { [styles.playing]: autoplayStatus && activeIndex == i }
                    )}
                  />
                  <defs>
                    <linearGradient id="a" x1="0" y1="0" x2="48" y2="0" gradientUnits="userSpaceOnUse">
                      <stop offset="0" stopColor="#DB5C00" />
                      <stop offset="1" stopColor="#E787B5" />
                    </linearGradient>
                    <linearGradient id="b" x1="5.534" y1="1.751" x2="45.654" y2="1.793" gradientUnits="userSpaceOnUse">
                      <stop offset=".021" stopColor="#DB9000" />
                      <stop offset=".157" stopColor="#DB5C00" />
                      <stop offset=".823" stopColor="#FA057B" />
                      <stop offset=".99" stopColor="#FA05E1" />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
            ))}
          </div>
          {breakpoint == "mobile" && <Mosaic onInteraction={() => setManuallyPaused(true)} entry={activeElement} />}
          <TimedEditorialCarouselSlide entry={activeElement} />
        </div>
        {breakpoint == "desktop" && (
          <div className={styles.col2}>
            <Mosaic entry={activeElement} onInteraction={() => setManuallyPaused(true)} />
          </div>
        )}
      </Grid>
    </Container>
  );
}
